export const environment = {
  apiUrl: 'https://api.base.28one-uat.com',
  ENV: 'uat',
  sentry: {"enable":false,"enableReplayIntegration":false,"enableBrowserTracingIntegration":false},
  dataName: 'riverisland',
  clientName: 'riverisland',
  enableReset: false,
  production: false,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://riverisland.28one-uat.com',
  navBar: {
    leftImage: 'assets/icons/riverisland.png',
    leftImageHeight: 30,
    menuSetting: '[{"name":"reports","default":true,"page-name":"ReportSectionf71766f04794c71c8443","report-id":"d712f7a6-cab1-42e5-a397-70868f7563ca"},{"name":"dashboards","default":false,"page-name":"ReportSection791bcdeec022f2c08cd5","report-id":"d712f7a6-cab1-42e5-a397-70868f7563ca"},{"name":"management report","default":false,"page-name":"ReportSectiona63adda1d0a45a065bae","report-id":"d712f7a6-cab1-42e5-a397-70868f7563ca"},{"name":"inspection","default":false}]',
    roleFilter: '{"inspection":[1,2,8]}',
    showAdmin: true,
    asDropdown: false,
  },
  sideBar: '{"inspection-plan-title":"AQL Inspection Plan","show-skip-inspections":true,"inspections-icon":"t-shirt.svg","show-po-selection":true,"show-packaging":false,"show-downloads":false}',
  import: '{"select-group":false,"title":"UPLOAD PRODUCT READY TO INSPECT","select-centre":true,"select-inspection-level":false,"select-inspection-type":true}',
  uploadSizeChart: '{"add-title":"ADD PRODUCT","need-upload":true,"title":"PRODUCT DETAILS","delete-title":"DELETE PRODUCT","product-code-title":"Style Code"}',
  clientInspection: '{"po-number":"PO Number","consol-filter":"Consol. Centre","table":[{"name":"Insp. Finished","case":"name","key":"inspection_date"},{"name":"Loc.","type":"number","case":"normal","key":"UDC_id"},{"name":"Sub.","type":"number","case":"normal","key":"inspection_number"},{"name":"Style Code","case":"normal","key":"style_code"},{"name":"Colour","case":"name","key":"colour"},{"name":"Season","case":"normal","key":"season_code"},{"name":"PO Numbers","case":"normal","key":"client_pos"},{"name":"Result","case":"result","key":"result"},{"name":"RI Decision","case":"client-decision","key":"client_decision"},{"name":"Submit","title":"Accept Changes","case":"client-decision-submit"},{"name":"Full Report","type":"pdf","case":"file"},{"name":"Summary Report","type":"pdf","case":"summary_file"},{"name":"Version History","case":"report_versions"},{"name":"Department","case":"name","key":"department"},{"name":"Vendor","case":"name","key":"supplier_name"},{"name":"Factory","case":"name","key":"factory_name"},{"name":"Lot Size","case":"name","key":"order_quantity"},{"name":"Sample Size","case":"name","key":"inspection_quantity"},{"name":"Total Defects","case":"name","key":"defect_quantity"},{"name":"Critical Defects","case":"name","key":"total_critical"},{"name":"A","case":"name","key":"A"},{"name":"B","case":"name","key":"B"},{"name":"C","case":"name","key":"C"},{"name":"D","case":"name","key":"D"},{"name":"E","case":"name","key":"E"},{"name":"F","case":"name","key":"F"},{"name":"G","case":"name","key":"G"},{"name":"H","case":"name","key":"H"},{"name":"I","case":"name","key":"I"},{"name":"J","case":"name","key":"J"},{"name":"K","case":"name","key":"K"},{"name":"L","case":"name","key":"L"},{"name":"M","case":"name","key":"M"}],"date-range-minus":10,"department-list":"Department","division-filter":"Division","colour-filter":"","result-filter":"Result"}',
  selectInspection: '{"order-number":true,"select-centre":true,"show-open-order-filter":false,"date-range-plus":0,"table":[{"name":"Arrived","case":"name","key":"arrived_consol_date"},{"name":"Size Chart","type":"number","case":"normal","key":"size_chart_YN"},{"name":"Insp. Type","case":"normal","key":"inspection_name"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Product","case":"normal","key":"style_code"},{"name":"TOT. QTY.","type":"number","case":"normal","key":"order_quantity"},{"name":"AQL","case":"check","key":"select_for_AQL"},{"name":"Inspection Date","case":"select_date","key":"inspection_date"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"PO Numbers","case":"normal","key":"client_pos","empty":true},{"name":"Action","case":"insp-action"}],"date-title":"Ready Date","date-range-minus":1}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","show":false,"case":"select_unselect","key":"qcdata_select_for_AQL"},{"name":"Insp. Type","show":true,"case":"name","key":"inspection_name"},{"name":"Insp. Date","show":true,"case":"name","key":"inspection_date"},{"name":"Loc.","show":true,"type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","show":true,"type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","show":true,"case":"name","key":"supplier_name"},{"name":"Season","show":true,"case":"normal","key":"season_code"},{"name":"PO Numbers","show":true,"case":"normal","key":"client_pos"},{"name":"STYLE","show":true,"case":"normal","key":"style_code"},{"name":"Description","show":true,"case":"name","key":"style_name"},{"name":"Colour","show":true,"case":"name","key":"colour"},{"name":"PO QTY.","show":true,"type":"number","case":"normal","key":"order_quantity"},{"name":"Level","show":true,"type":"number","case":"level","key":"sample_level"},{"name":"INSP. Qty.","show":true,"type":"number","case":"normal","key":"inspection_quantity"},{"name":"SUB.","show":false,"type":"number","case":"normal","key":"inspection_number"},{"name":"AQL Maj<","show":true,"type":"number","case":"normal","key":"allowed_defects_maj"},{"name":"AQL Min<","show":true,"type":"number","case":"normal","key":"allowed_defects_min"},{"name":"Tot. Defects","show":true,"type":"number","case":"normal","key":"count_defects"},{"name":"Current Status","show":true,"case":"normal","key":"status_EN"},{"name":"Result","show":true,"case":"result","key":"result"},{"name":"Inspector Name","show":true,"case":"normal","key":"Inspector"},{"name":"Customer","show":false,"case":"normal","key":"customer_name"}],"select-centre":true,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"field":"style_code","title":"Product Code"},{"field":"style_name","title":"Product Name"},{"field":"colour","title":"Colour"},{"field":"supplier_name","title":"Supplier"},{"field":"PO_Nos","title":"PO Number"},{"field":"Order_Size","title":"Order Size"},{"field":"inspection_number","title":"Submission"},{"field":"sample_level","title":"Inspection Level"},{"field":"inspection_quantity","title":"Sample Qty."}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":false,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[]},
};